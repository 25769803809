.tableFooter {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
        .showWrapper {
            display: flex;
            justify-content: center;

            p:first-child {
                padding-right: 0px;
            }
        }
    }

    & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p:first-child {
            padding-right: 30px;
        }
    }

    .paginationText {
        text-transform: uppercase;
        margin: 0;
        font-family: "Circular-Std-Book";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #9292a3;

        display: flex;
        align-items: center;

        span {
            font-family: "Circular-Std-Medium";
            font-weight: 500;
            color: #3d3d46;
            padding: 0px 5px;
        }
    }

    .tblPagination {
        :global {
            .ant-pagination-item {
                height: 33px;
                box-sizing: border-box;
                border-radius: 4px;
            }
        }
    }
}
@media (min-width: 240px) and (max-width: 767px) {
    .tableFooter {
        flex-direction: column-reverse;
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 6px;
    }

    .paginationText {
        text-align: center;
        padding-top: 19px;
    }

    .tblPagination {
        display: flex;
        justify-content: center;
    }
}
