.login {
    width: 40rem;
    .card {
        border-radius: 18px;
        align-self: center;
        height: 100%;
        .content {
            padding: 2rem 3.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2.5rem;
            .header {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title {
                    font-weight: 700;
                    font-size: 2rem;
                }
                .subTitle {
                    color: var(--gray-color);
                }
            }
            .form {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 0.8rem;
            }
        }
    }
    @media screen and (max-width: 768px) {
        width: 90%;
        .card {
            .content {
                padding: 3rem 1.5rem;
            }
        }
    }
    @media screen and (max-width: 550px) {
        width: 90%;
        .card {
            .content {
                padding: 3rem 0rem;
                .header {
                    .title {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
