.user {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .header {
        .back {
            display: flex;
            align-items: center;
            gap: 0.7rem;
        }
        svg {
            color: var(--primary-color);
            width: 2rem;
            height: 1.2rem;
            cursor: pointer;
        }
    }
    .main {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        .info {
            border-radius: 10px;
            background-color: var(--white-color);
            .head {
                display: flex;
                flex-direction: column;

                .cover {
                    height: 6rem;
                    background-color: var(--primary-color);
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .avatar {
                        display: flex;
                        justify-content: center;
                        transform: translateY(-50%);

                        .text {
                            text-transform: capitalize;
                            font-size: 40px;
                        }
                        :global {
                            .ant-avatar-lg {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 110px;
                                height: 110px;
                                border: 3px solid var(--white-color);
                            }
                        }
                    }
                    .personal {
                        transform: translateY(-40px);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 0.4rem;
                        .name {
                            font-size: 32px;
                            font-weight: 600;
                        }
                        .role {
                            color: var(--primary-color);
                            font-weight: 600;
                        }
                        .linkedIn {
                            text-decoration: underline;
                            color: var(--dark-gray);
                            display: flex;
                            justify-content: end;
                            align-items: end;
                            gap: 1px;
                            cursor: pointer;
                            font-weight: 600;

                            svg {
                                width: 11px;
                                height: 11px;
                            }
                        }
                    }
                }
            }
            .personalDetails {
                display: flex;
                gap: 3rem 1rem;
                flex-wrap: wrap;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-bottom: 2rem;
            }
        }
        .settings {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
            .title {
                font-size: 1.6rem;
                color: var(--primary-color);
            }
            .content {
                background-color: var(--white-color);
                border-radius: 10px;
                display: flex;
                padding: 2rem 2rem;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 1.5rem;
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 2rem;
                    .label {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

.userDetailItem {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 1rem);
    min-width: 200px;
    .label {
        color: var(--primary-color);
        font-size: 1.2rem;
        font-weight: bold;
    }
    .val {
        font-size: 1.1rem;
    }
    .nullVal {
        color: var(--gray-color);
    }
}

.userErrorMsg {
    display: flex;
    justify-content: center;
    color: var(--red-color);
}
