.addConfigurationForm {
    .item {
        padding: 1.5rem 0rem;
    }
    .btnBlock {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1rem;
        button {
            width: 6rem;
        }
    }
}

.applyFilter {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .form {
        margin-top: 0.7rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        .select {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            .label {
            }
            .checkboxGroup {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }
    }
    .btnBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}

.selectLoading {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
