.post {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .back {
            display: flex;
            align-items: center;
            gap: 0.7rem;
        }
        svg {
            color: var(--primary-color);
            width: 2rem;
            height: 1.2rem;
            cursor: pointer;
        }
        .actionBtns {
            display: flex;
            gap: 0.6rem;
            .needChangeBtn {
                background-color: var(--yellow-color);
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .main {
        .head {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: var(--primary-color);
            height: 2rem;
        }
        .content {
            padding: 2rem 10rem;
            background-color: var(--white-color);
            display: flex;
            flex-direction: column;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            gap: 3rem;
            &__user {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .user {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    :global {
                        .ant-avatar-lg {
                            width: 60px;
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .ant-avatar-string {
                                font-size: 1.7rem;
                            }
                        }
                    }
                    .name {
                        font-size: 1.4rem;
                        font-weight: 600;
                    }
                }
            }
            .postDetails {
                display: flex;
                flex-direction: column;
                gap: 1.3rem;
                .item {
                    .title {
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                    .postTitle {
                        font-size: 1.6rem;
                    }
                    .speciality {
                        font-size: 1.1rem;
                        font-weight: 600;
                    }
                    .postContent {
                        font-size: 1.1rem;
                    }
                    .status {
                        margin-top: 0.3rem;
                        padding: 2px 12px;
                        font-size: 13px;
                    }
                }
            }
            .footer {
                display: flex;
                gap: 2.5rem;
                .flag {
                    color: var(--gray-color);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 0.2rem;
                    .flagVal {
                        font-weight: 600;
                    }
                }
            }
        }

        .adminFeedbacks {
            margin-top: 1rem;
            .feedbackTitle {
                color: var(--primary-color);
                font-size: 1.4rem;
            }
            .feedbacks {
                background-color: var(--white-color);
                margin-top: 1rem;
                padding: 1rem 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                border-radius: 8px;
                .feedbackItem {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    .date {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        font-size: 0.8rem;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .content {
                padding: 3rem 5rem;
            }
        }
        @media screen and (max-width: 550px) {
            .content {
                padding: 3rem 1rem;
            }
            .adminFeedbacks {
                .feedbacks {
                    padding: 0 1rem;
                }
            }
        }
    }
}

.postErrorMsg {
    display: flex;
    justify-content: center;
    color: var(--red-color);
}

.notFound {
    color: var(--gray-color);
}

.feedbackForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .btnBlock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        :global {
            .ant-btn {
                width: 6rem;
            }
        }
    }
}

.postDetailBottom {
    height: 60px;
}
