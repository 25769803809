.dashboardLayout {
    background-color: var(--light-color);
    height: 100%;
    .navbar {
        background-color: var(--primary-color);
        padding: 0rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            padding: 0.7rem 0rem;
            svg {
                width: 4.4rem;
                height: 2rem;
            }
        }
        .tabs {
            align-self: end;
            .item {
                display: flex;
                gap: 0.8rem;
                align-items: center;
                .text {
                    font-weight: 500;
                }
            }
            :global {
                .ant-tabs-tab-btn {
                    color: var(--white-color);
                    margin-bottom: 11px;
                }
                .ant-tabs {
                    margin-bottom: -19px;
                }
                .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                        height: 100%;
                    }
                }
                .ant-tabs-nav-list {
                    .ant-tabs-ink-bar {
                        height: 3px;
                        background-color: var(--primary-color);
                    }
                }
            }
        }
    }
    .main {
        padding: 0rem 1.9rem;
        margin-top: 2rem;
    }
}

.dropdownItem {
    &:hover {
        color: var(--primary-color);
    }
}
.profileImg {
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
        transform: scale(1.06);
    }
}
