.dragTable {
    :global {
        .ant-table-thead > tr > th {
            background: var(--light-color);
            border-top: 1px solid #f1f1f1;
        }

        .ant-table-tbody {
            tr {
                border-bottom: 1px solid #f1f1f1;
            }

            td {
                @media screen and (max-width: 767px) {
                    width: 155px;
                }
            }
        }

        tr:hover {
            background-color: var(--primary-color);
            cursor: pointer;
        }

        .ant-table-cell .drag-visible {
            cursor: pointer !important;
        }

        .ant-table-cell {
            .ant-table-expanded-row-fixed {
                text-align: center;
                padding: 24px;
                font-family: "Circular-Std-Book";
                font-size: 14px;
                font-weight: 500;
            }
        }

        .alignCenter {
            text-align: center;
        }
    }

    .alignItems {
        display: flex;
        align-items: center;

        .customColor {
            width: 20px;
            height: 10px;
            border-radius: 4px;
            margin-right: 20px;
        }
    }

    .tableIcon {
        margin-left: 6px;
        margin-right: 6px;
        font-size: 14px;
    }
}

:global {
    .ant-spin-container {
        background-color: var(--white-color);
        .ant-table {
            .ant-table-container {
                .ant-table-content {
                    .ant-table-thead {
                        tr th {
                            background-color: var(--primary-color);
                            color: var(--white-color);
                            padding: 10px 20px;
                        }
                    }
                    .ant-table-tbody {
                        tr {
                            td {
                                background-color: var(--white-color);
                            }
                        }
                    }
                }
            }
        }
        .ant-pagination {
        }
    }
}

.activeBtn {
    background: rgba(84, 178, 211, 0.05);
    border-radius: 4px;
    border: 1px solid rgba(84, 178, 211, 0.5);
    color: var(--primary-color);
    cursor: pointer;
    font-family: Circular-Std-Book;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding: 1px 12px;
    width: 106px;
    text-transform: none;
    height: 24px;
}

.disableSourceBtn {
    background: rgba(165, 169, 170, 0.05);
    border: 1px solid rgba(165, 169, 170, 0.5);
    color: #a5a9aa;
    font-family: Circular-Std-Book;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding: 1px 12px;
    width: 106px;
    border-radius: 4px;
    height: 24px;
    text-transform: none;
    cursor: pointer !important;
}

.codeBtn {
    background: #f7f7f9;
    border-radius: 4px;
    cursor: pointer;
    font-family: Circular-Std-Book;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding: 1px 12px;
    width: 106px;
    text-transform: none;
    height: 24px;
    border: 1px dashed #9292a2;

    &:hover {
        background: #f7f7f9;
        color: rgba(0, 0, 0, 0.65);
        border-color: var(--border-color-base);
    }
}
