.userListing {
    .userNameColumn {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .nameInfo {
            display: flex;
            flex-direction: column;
            .email {
                color: var(--gray-color);
                font-size: 0.8rem;
            }
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .options {
            display: flex;
            align-items: end;
            gap: 1rem;
            .filter {
                display: flex;
                gap: 0.5rem;
                :global {
                    .ant-btn {
                        display: flex;
                    }
                }
            }
            .sort {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .select {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: end;
                    gap: 0.4rem;
                    .label {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    .main {
        margin-top: 2rem;
        .inappropriateFlagColumn {
            text-align: center;
            width: 130px;
        }
    }
}

.textCenter {
    text-align: center;
}
