.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageTitle {
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 500;
}

.primary-text {
  color: var(--primary-color);
}
.danger-text {
  color: var(--red-color);
}

.yellow-text {
  color: var(--yellow-color);
}

.badge {
  font-size: 0.7rem;
  padding: 3.4px 10px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.primary-badge {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.yellow-badge {
  background-color: var(--yellow-color);
  color: var(--white-color);
}
.danger-badge {
  background-color: var(--red-color);

  color: var(--white-color);
}
.bg-yellow {
  background-color: var(--yellow-color);
}

.ant-switch.ant-switch-checked {
  background: var(--success-color);
}

.ant-switch:hover {
  background-color: var(--success-light-color) !important;
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: var(--success-color);
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--success-light-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid var(--success-light-color) !important;
}
