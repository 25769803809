.postListing {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .options {
            display: flex;
            align-items: end;
            gap: 1rem;
            .filter {
                display: flex;
                gap: 0.5rem;
                :global {
                    .ant-btn {
                        display: flex;
                    }
                }
            }
            .sort {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .select {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: end;
                    gap: 0.4rem;
                    .label {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    .tabs {
        :global {
            .ant-tabs-tab {
                color: var(--dark-gray);
            }
        }
    }
    .main {
        .inappropriateFlagColumn {
            text-align: center;
            width: 100px;
        }
        .totalVotesColumn {
            width: 120px;
        }
        .dateAddedColumn {
            width: 130px;
            text-align: center;
        }
        .postTitle {
            max-width: 100%;
            min-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: pre-wrap;
        }
        .postTitleColumn {
            max-width: 460px;
        }
    }
}

.textCenter {
    text-align: center;
}
