.setting {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .skeleton {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .item {
            display: flex;
            .key {
                width: 200px;
            }
            .switch {
                width: 10px;
                max-width: 10px;
            }
        }
    }
    .main {
        margin-top: 1.8rem;
        .card {
            width: 100%;
            padding: 0;
            border-radius: 16px;
            overflow: hidden;
            .header {
                background: var(--primary-color);
                height: 2rem;
            }
            .body {
                padding: 2.5rem 1.6rem;
                .item {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    .name {
                        font-size: 1.1rem;
                        font-weight: 600;
                    }
                }
            }
            :global {
                .ant-card-body {
                    padding: 0px;
                }
            }
        }
    }
}
