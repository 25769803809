.container {
    background-color: var(--primary-color);
    overflow: hidden;
    height: 100%;

    .content {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        .header {
            margin-top: 2rem;
            svg {
                height: 6rem;
                width: 13rem;
            }
        }
        .footer {
            width: 100%;
            text-align: center;
            padding: 0.9rem;
            color: var(--white-color);
        }
    }
}
