$red-color: #e10b0e;
$white-color: #fff;
$primary-color: #69a2d3;
$gray-color: #8c8c8c;
$dark-color: #000000;
$light-color: #f4f7fe;

:root {
    --red-color: #{$red-color};
    --white-color: #{$white-color};
    --primary-color: #{$primary-color};
    --gray-color: #{$gray-color};
    --dark-color: #{$dark-color};
    --light-color: #{$light-color};
    --dark-gray: #323232;
    --yellow-color: #f0c045;
    --primary-light: #8dc2f0;
    --success-color: #12b76a;
    --success-light-color: rgb(74, 212, 148);
    --dark-gray: rgba(52, 52, 52, 0.6);
}
