.configuration {
    background-color: var(--light-color);
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .filter {
            display: flex;
            align-items: center;
            gap: 0.6rem;
        }
    }
    .table {
        margin-top: 2rem;
        .actionColumn {
            display: flex;
            gap: 0.5rem;
            button {
                width: 6rem;
            }
        }
    }
    .tabs {
        margin-top: 1rem;
    }
}
